import { errorActions } from '@docavenue/core';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from '@/components/atoms/Countdown/Countdown';
import { Trans } from '@/src/i18n';
import Alert from '../../atoms/Alert/Alert';
import { buildErrorTranslationKeys } from '@/src/utils';

type RequestErrorAlertProps = {
  resource: string;
  action: string;
  useDefaultGenericMessage?: boolean;
};

const RequestErrorAlert = (props: RequestErrorAlertProps) => {
  const { resource, action, useDefaultGenericMessage } = props;
  const dispatch = useDispatch();
  const error = useSelector(state =>
    get(state, `errors.${resource}_${action.toLowerCase()}`),
  );

  if (!error) return null;

  const errorKeys = buildErrorTranslationKeys(error, {
    ...(useDefaultGenericMessage === true && {
      defaultKey: 'error:generic_message',
    }),
    prefix: 'error:',
  });

  return (
    <Alert dataCy="form-error">
      {error.code === 'USER_TEMPORARILY_BLOCKED' ? (
        <Trans
          i18nKey="error:user_temporarily_blocked_try_again_in"
          components={{
            countdown: (
              <Countdown
                until={
                  +new Date() + error.remainingBlockingTimeInSeconds * 1000
                }
                onTimedOut={() => {
                  dispatch(
                    errorActions.removeError({
                      resource,
                      crudActionType: action?.toLowerCase(),
                    }),
                  );
                }}
              />
            ),
          }}
        />
      ) : (
        <Trans
          i18nKey={errorKeys}
          defaults={error.message}
          values={{ attempts: error?.nbRemainingAttempts }}
          components={{ b: <b /> }}
        />
      )}
    </Alert>
  );
};

export default RequestErrorAlert;
